<template>
    <div class="table">
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            key="1"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '13px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="6"> </el-table-column>

            <el-table-column label="导入时间" show-overflow-tooltip width="150">
                <template slot-scope="scope">
                    <span>{{
                        returnFollowDate(scope.row.createTime)
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="导入人" show-overflow-tooltip width="90">
                <template slot-scope="scope">
                    <span>{{ scope.row.adminName }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="源文件" show-overflow-tooltip width="230">
                <template slot-scope="scope">
                    <span
                            class="info"
                            @click="openFile(scope.row.originalUrl)"
                            >{{
                                scope.row.originalName || '- -'
                            }}</span
                        >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="结果文件" show-overflow-tooltip width="230">
                <template slot-scope="scope">
                    <span
                            class="info"
                            @click="openFile(scope.row.resultUrl)"
                            >{{
                                scope.row.resultUrl || '- -'
                            }}</span
                        >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="总条数" show-overflow-tooltip width="90">
                <template slot-scope="scope">
                    <span>{{scope.row.totalCount}}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="成功条数" show-overflow-tooltip width="90">
                <template slot-scope="scope">
                    <span>{{scope.row.successCount}}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="失败条数" show-overflow-tooltip width="90">
                <template slot-scope="scope">
                    <span>{{scope.row.failCount}}</span>
                </template>
            </el-table-column>

            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        
        
       
        
    </div>
</template>
<script>
import { customerRecordHistory } from '@/api/customer/customer.js';
export default {
    data() {
        return {
            tableData: [],
            btnP: {},
            pageData: {},
            baseUrl: this.$url.templateUrl() + '/excel/',
        };
    },
    methods: {
        getData(searchVal, btnP) {
            this.pageData = searchVal;
            customerRecordHistory(searchVal).then((res) => {
                this.tableData = res.data.list;
                this.$emit('totalNub', res.data.total);
                this.btnP = btnP;
            });
        },
        returnFollowDate(followTime) {
            console.log(followTime);
            return followTime;
        },
        openFile(file) {
            let fileTemp = this.baseUrl + file;
            window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' + fileTemp
            );
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
/deep/ th:nth-child(3) .cell {
    padding-left: 30px;
}
.table {
    width: 100%;
    height: 100%;
}
/deep/ td:nth-child(4) {
    padding: 8px 0 !important;
}
.blue {
    color: #2370eb;
    font-size: 12px;
}
.yellow {
    color: #ff703b;
    font-size: 12px;
}
.info {
    color: #2370eb;
    cursor: pointer;
    display: block;
    overflow: hidden;
}
.icon-shoucang {
    color: #feb516;
}
.icon-star {
    color: #ccc;
}
.Times {
    height: 24px;
    background: #fff2ed;
    border-radius: 2px;
    border: 1px solid #ff7a44;
    display: inline-block;
    padding: 2px 5px;
    margin-right: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #ff7a45;
}
.customer_name {
    display: flex;
    align-items: center;
    > div {
        p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            span + span {
                margin-left: 7px;
            }
        }
    }
}
/deep/ .el-table__row td {
    font-size: 12px !important;
}
.companyDomain {
    font-size: 12px;
    p + p {
        margin-top: 10px;
    }
}
.icon-dianhua {
    color: #2370eb;
}
.icon-weixin {
    color: #50b674;
}
.icon-QQ {
    color: #2370eb;
}
.icon-youxiang {
    color: #feb516;
}
.icon-qita {
    color: #13ab16;
}
.icon-yuyuebaifang {
    color: #f15641;
}
.icon-qita1 {
    color: #af90ff;
}
.icon-zuoji {
    color: #6a58ca;
}
.icon-duanxinxiaoxixinxi {
    color: #13ab16;
}
.icon-diannao {
    color: #87b1e9;
}
.company_contact {
    .icon-dianhua,
    .icon-weixin,
    .icon-QQ,
    .icon-youxiang,
    .icon-qita,
    .icon-youxiang1,
    .icon-yuyuebaifang,
    .icon-qita1,
    .icon-diannao,
    .icon-duanxinxiaoxixinxi,
    .icon-zuoji {
        position: static;
        left: 0;
        top: 0;
    }
    p {
        span {
            display: inline-block;
            margin: 0 8px;
            width: 50px;
        }
    }
}
.icon-renzheng {
    position: absolute;
    left: -10px;
    top: 49%;
    transform: translateY(-50%);
    color: #f5a623;
}
.icon-shijian01 {
    position: absolute;
    left: 4px;
    top: 51%;
    transform: translateY(-50%);
    color: #bc70ff;
}
.deal_summary {
    span {
        cursor: pointer;
        color: #2370eb;
    }
    .ssspan {
        display: inline-block;
        width: 55px;
    }
}
.deal_summary_popover {
    font-size: 12px;
    span {
        display: inline-block;
        cursor: pointer;
        user-select: none;
    }
    .ssspan {
        display: inline-block;
        width: 55px;
    }
}
.circle_toast {
    font-size: 12px;
    display: inline-block;
    width: 12px;
    height: 12px;
    background: #f7b84f;
    color: #fff;
    line-height: 12px;
    border-radius: 50%;
    text-align: center;
    font-style: normal;
    position: absolute;
    right: 0;
    top: 49%;
    transform: translateY(-50%);
}
.tag_list {
    span + span {
        margin-left: 10px;
    }
}
</style>
